<template>
  <v-row>
    <div class="col-sm-12 col-md-8 col-lg-12 container-list">
      <v-expansion-panels>
        <v-expansion-panel  v-for="(categoria, index) in categorias" :key="index">
          <v-expansion-panel-header class="negroClaro">
            <h2 class="title-submenu">{{categoria.title}}</h2>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="negroClaro">
            <div>
              <div
                class="containerBanner mb-8"
                v-if="categoria.urlImage"
                v-bind:style="{ 'background-image': 'url(' + categoria.urlImage + ')' }"
              >
              </div>
              <ListPlatillos :platillos="categoria.platillos" v-if="categoria.platillos"></ListPlatillos>
              <ListSubcategorias :subcategorias="categoria.subcategorias" v-if="categoria.subcategorias"></ListSubcategorias>
              <div v-if="categoria.comentarios" class="comentarios-pie" v-html="categoria.comentarios" /> 
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </v-row>
</template>
<script>
import ListPlatillos from "@/components/ListPlatillos";
import ListSubcategorias from "@/components/ListSubcategorias";
export default {
  props:{
    categorias: Array,
    colorText: {
      type: String,
      default: ""
    }
  },
  methods: {
  },
  components: {
    ListPlatillos,
    ListSubcategorias
  }
};
</script>
<style lang="scss">
.container-list{
  margin: 0 auto;
}
.containerBanner {
  height: 150px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  box-sizing: border-box;
}
.title-submenu {
  font-size: 16px;
  font-weight: normal;
}
.v-expansion-panel-header{
  .v-expansion-panel-header__icon{
    color: currentColor;
    .v-icon{
      color: currentColor !important;
      font-size: 32px;
    }
  }
}
.comentarios-pie{
  font-size: 14px;
  p{
    margin-bottom: 0;
  }
}
</style>
<template>
  <v-container>
    <div class="container-global">
      <h2 class="mb-6">Membresias</h2>
      <TableMembresia></TableMembresia>
    </div>
  </v-container>
</template>
<script>
import TableMembresia from "@/components/membresia/TableMembresia";
export default {
  components: {
    TableMembresia,
  },
};
</script>
<template>
  <div class="container-table-planes">
    <v-dialog v-model="loadingData" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Cargando
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <button @click="mostrarAgregarMembresia()" class="btn-add white--text mb-6">
      <svg xmlns="http://www.w3.org/2000/svg" width="25.8" height="25.8">
        <g data-name="Grupo 51" transform="translate(-291.861 4.825)">
          <circle
            data-name="Elipse 15"
            class="color-add"
            cx="12.15"
            cy="12.15"
            r="12.15"
            transform="translate(292.611 -4.075)"
          />
          <path
            data-name="Línea 22"
            class="color-add"
            transform="translate(304.761 1.765)"
            d="M0 0v12.619"
          />
          <path
            data-name="Línea 23"
            class="color-add"
            transform="translate(298.451 8.075)"
            d="M0 0h12.619"
          />
        </g>
      </svg>
      Agregar Membresia
    </button>
    <v-data-table
      :headers="headers"
      :items="listMembresias"
      sort-by="nombre"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <v-form
              ref="form"
              @submit.prevent="save()"
              v-model="valid"
              lazy-validation
            >
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="6">
                        <v-text-field
                          v-model="editedItem.nombre"
                          :rules="nameRules"
                          label="Título"
                          color="primary"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="6">
                        <v-text-field
                          v-model="editedItem.precio"
                          :rules="numberRules"
                          color="primary"
                          label="Precio"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedItem.cantidad"
                          :rules="cantidadRestRules"
                          color="primary"
                          label="Cantidad de restaurantes"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-textarea
                          label="Descripcion"
                          v-model="editedItem.descripcion"
                          no-resize
                          :rules="descripcionRules"
                          color="primary"
                          rows="1"
                          required
                        ></v-textarea>
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          :items="listDuracion"
                          v-model="editedItem.duracion"
                          :rules="duracionRules"
                          color="primary"
                          label="Duración"
                          required
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <p class="primary--text font-weight-bold">
                          Servicios que ofrece el plan:
                        </p>
                        <div class="d-flex flex-column">
                          <v-checkbox
                            v-model="editedItem.encuesta"
                            label="Encuesta"
                            class="pa-0 ma-0"
                            color="primary"
                          ></v-checkbox>
                          <v-checkbox
                            v-model="editedItem.subir"
                            label="Subir menu"
                            class="pa-0 ma-0"
                            color="primary"
                          ></v-checkbox>
                          <v-checkbox
                            v-model="editedItem.redes"
                            label="Redes sociales"
                            class="pa-0 ma-0"
                            color="primary"
                          ></v-checkbox>
                        </div>
                      </v-col>
                      <v-col cols="12">
                        <div
                          class="drag-inputFile mb-8"
                          @drop.stop.prevent="onDrop($event, editedItem)"
                          @dragover.prevent
                          @dragenter.prevent
                        >
                          <label for="file-plan" class="select"></label>
                          <input
                            @change="changeImg($event, editedItem)"
                            id="file-plan"
                            class="input-file"
                            accept=".png, .jpg"
                            type="file"
                          />
                          <div
                            class="img-preview"
                            v-if="editedItem.urlImage"
                            v-bind:style="{
                              'background-image':
                                'url(' + editedItem.urlImage + ')',
                            }"
                          ></div>
                          <v-icon
                            small
                            class="editBtn"
                            v-if="editedItem.urlImage"
                            >mdi-pencil</v-icon
                          >
                          <v-icon
                            small
                            class="deleteBtn"
                            @click="removeImg(editedItem)"
                            v-if="editedItem.urlImage"
                            >mdi-delete</v-icon
                          >
                          <div class="upload">
                            <img
                              class="icon-camara"
                              :src="require('@/assets/camara.svg')"
                              alt="icon camara"
                            />
                            <p class="label-black">Seleccionar archivo</p>
                            <p class="label-instruccion">
                              o arrastra una imagen aquí.
                            </p>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="errorColor" text @click="close">Cancelar</v-btn>
                  <v-btn
                    type="submit"
                    color="primary"
                    text
                    :loading="loading"
                    :disabled="!valid"
                    >Guardar</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-form>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Estas seguro que deseas eliminar este platillo?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancelar</v-btn
                >
                <v-btn
                  color="blue darken-1"
                  text
                  @click="deleteItemConfirm"
                  :loading="loading"
                  >Confirmar</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="d-flex justify-end">
          <button type="button" @click="editItem(item)" class="mr-4">
            <img
              width="18px"
              :src="require('@/assets/icon-edit.svg')"
              alt="icon edit"
            />
          </button>
          <button type="button" @click="deleteItem(item)" class="mr-4">
            <img
              width="15.8px"
              :src="require('@/assets/icon-delete.svg')"
              alt="icon delete"
            />
          </button>
          <v-switch
            color="secondary"
            dense
            v-model="item.estatus"
            v-on:change="openDialogEstatus(item)"
          ></v-switch>
        </div>
      </template>
      <template v-slot:no-data>
        <p>No hay planes</p>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      listDuracion: ["1 mes", "3 meses"],
      dialog: false,
      dialogDelete: false,
      valid: false,
      loading: false,
      loadingData: false,
      nameRules: [(v) => !!v || "El título es obligatorio"],
      descripcionRules: [(v) => !!v || "La descripción es obligatoria"],
      duracionRules: [(v) => !!v || "La duraciòn es obligatoria"],
      numberRules: [
        (v) =>
          Number.isInteger(Number(v)) || "El precio debe ser un número entero",
        (v) => v > 0 || "El precio es obligatorio",
      ],
      cantidadRestRules: [
        (v) =>
          Number.isInteger(Number(v)) ||
          "La cantidad debe ser un número entero",
        (v) => v > 0 || "La cantidad es obligatorio",
      ],
      headers: [
        {
          text: "Nombre",
          align: "start",
          sortable: false,
          value: "nombre",
        },
        { text: "Descripción", value: "descripcion", sortable: false },
        { text: "Precio", value: "precio", sortable: false },
        { text: "Acciones", value: "actions", sortable: false },
      ],
      editedIndex: -1,
      editedItem: {
        id: 0,
        urlImage: "",
        imagen: null,
        nombre: "",
        descripcion: "",
        duracion: "",
        precio: 0,
        cantidad: 1,
        encuesta: false,
        subir: false,
        redes: false,
        estatus: true,
      },
      defaultItem: {
        id: 0,
        urlImage: "",
        imagen: null,
        nombre: "",
        descripcion: "",
        duracion: "",
        precio: 0,
        cantidad: 1,
        encuesta: false,
        subir: false,
        redes: false,
        estatus: true,
      },
      urlDefaultImage: require("@/assets/default.png"),
    };
  },
  computed: {
    ...mapState("adminMembresias", ["listMembresias"]),
    formTitle() {
      return this.editedIndex === -1 ? "Agregar" : "Editar";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  created() {
    this.obtieneMembresias();
  },
  methods: {
    ...mapActions("adminMembresias", [
      "crearMembresia",
      "obtieneMembresias",
      "actualizarMembresias",
    ]),
    mostrarAgregarMembresia() {
      this.dialog = true;
    },
    removeImg(editedItem) {
      editedItem.imagen = null;
      editedItem.urlImage = "";
    },
    //asigna el elemento a editar
    editItem(item) {
      this.editedIndex = this.listMembresias.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    close() {
      this.resetValidation();
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    //evento drop input file
    onDrop(event, editedItem) {
      const file = event.dataTransfer.files[0];
      this.processImage(file, editedItem);
    },
    //evento cuando cambia el archivo
    changeImg(event, editedItem) {
      const file = event.target.files[0];
      this.processImage(file, editedItem);
    },
    //evento que valida la imagen que se ejecuta con los eventos change, drop
    processImage(file, editedItem) {
      var ext = file.name.split(".").pop();
      if (ext == "png" || ext == "jpg") {
        if (file.size <= 512017) {
          if (file) {
            editedItem.imagen = file;
            this.imageProcess(file, editedItem);
          }
        } else {
          this.$swal({
            showConfirmButton: false,
            timer: 3000,
            icon: "error",
            timerProgressBar: true,
            title: "El tamaño maximo permitido es de 500kb",
          });
        }
      } else {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          timerProgressBar: true,
          title: "Solo se permiten archivos de tipo .png, .jpg",
        });
      }
    },
    //funcion que asigna la imagen que se carga
    imageProcess(file, editedItem) {
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          editedItem.urlImage = e.target.result;
        };
      } else {
        editedItem.urlImage = this.urlDefaultImage;
      }
    },
    removeImg(editedItem) {
      editedItem.imagen = null;
      editedItem.urlImage = "";
    },
    //asigna el elemento a eliminar
    deleteItem(item) {
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    save() {
      //se validan los campos
      if (!this.$refs.form.validate()) return;
      if (this.editedIndex > -1) {
        this.updatePlan();
      } else {
        this.savePlan();
      }
    },
    async updatePlan() {
      this.loading = true;
      const result = await this.actualizarMembresias(this.editedItem);
      if (result.res) {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "success",
          timerProgressBar: true,
          title: "Se actualizó correctamente",
        });
      } else {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          timerProgressBar: true,
          title: result.error,
        });
      }
      this.loading = false;
      this.close();
    },
    async savePlan() {
      this.loading = true;
      const result = await this.crearMembresia(this.editedItem);
      if (result.res) {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "success",
          timerProgressBar: true,
          title: "Se agregó correctamente",
        });
      } else {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          timerProgressBar: true,
          title: result.error,
        });
      }
      this.loading = false;
      this.close();
    },
    async deleteItemConfirm() {},
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>
<template>
  <div class="modal-platillo">
    <div class="plasta" v-on:click="cerrarModal()"></div>
    <div class="container-img">
      <div class="align-button">
        <button class="imgClose" v-on:click="cerrarModal()">
          <img :src="require('@/assets/icon-close.svg')" alt="icon close" />
        </button>
      </div>
      <v-img alt="img platillo" class="shrink" contain :src="urlImgPlatilloModal" max-width="100%" max-height="70%" />
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { gsap } from "gsap";
export default {
  methods: {
    cerrarModal() {
      gsap.to(".modal-platillo", { duration: 0.6, opacity: 0, zIndex: -1 });
    }
  },
  computed: {
    ...mapState(["urlImgPlatilloModal"])
  }
};
</script>
<style lang="scss">
.modal-platillo {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  z-index: -1;
  opacity: 0;
  .plasta {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    height: 100%;
    width: 100%;
    z-index: 1;
  }
  .imgClose {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
  }
  .align-button{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;
  }
  .container-img {
    width: 95%;
    margin: 0 auto;
    position: relative;
    z-index: 2;
    img {
      max-width: 100%;
    }
    @media (min-width: 1024px) {
      width: 30%;
    }
  }
}
</style>
<template>
  <v-form
    ref="form"
    v-model="valid"
    @submit.prevent="guardarSuscripcion()"
    lazy-validation
  >
    <v-select
      :items="listUsuarios"
      :item-text="'email'"
      v-model="data.selectedUsuario"
      :item-value="'id'"
      :rules="rulesUsuario"
      name="usuarios"
      label="Selecciona un usuario"
      color="primary"
    ></v-select>
    <v-select
      :items="listMembresias"
      :item-text="'nombre'"
      :rules="rulesMembresia"
      v-model="data.selectedMembresia"
      :item-value="'id'"
      name="usuarios"
      label="Selecciona un membresia"
      color="primary"
    ></v-select>
    <v-btn
      color="buttonPrimary"
      class="white--text pr-8 pl-8 btn-margin"
      :loading="loading"
      :disabled="!valid"
      type="submit"
      >Guardar cambios</v-btn
    >
  </v-form>
</template>
<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";
export default {
  data: () => ({
    valid: false,
    data: {
      selectedUsuario: "",
      selectedMembresia: "",
    },
    rulesUsuario: [(v) => !!v || "El usuario es requerido"],
    rulesMembresia: [(v) => !!v || "El tipo de membresia es requerido"],
    loading: false,
  }),
  computed: {
    ...mapState("adminSuscripciones", ["listUsuarios", "listMembresias"]),
  },
  created() {
    this.obtieneUsuarios();
    this.obtieneMembresias();
  },
  methods: {
    ...mapActions("adminSuscripciones", [
      "obtieneUsuarios",
      "obtieneMembresias",
      "guardaMembresia",
    ]),
    moment: function () {
      return moment();
    },
    async guardarSuscripcion() {
      if (!this.$refs.form.validate()) return;
      this.loading = true;
      const usuarioData = this.listUsuarios.filter(
        (item) => item.id == this.data.selectedUsuario
      );
      const membresiaData = this.listMembresias.filter(
        (item) => item.id == this.data.selectedMembresia
      );
      if (!membresiaData.length > 0) {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          timerProgressBar: true,
          title: result.error,
        });
        return;
      }
      let numMeses = this.getMeses(membresiaData[0].duracion);
      const fechaInicioCurrent = moment(new Date())
        .locale("en")
        .format("MMMM Do YYYY, h:mm:ss a");
      const fechaFinMes = moment(new Date())
        .add(numMeses, "months")
        .locale("en")
        .format("MMMM Do YYYY, h:mm:ss a");
      const fechasObject = {
        fechaInicio: fechaInicioCurrent,
        fechaFin: fechaFinMes,
      };
      const dataParam = {...usuarioData[0], ...membresiaData[0], ...fechasObject};
      const result = await this.guardaMembresia(dataParam);
      if (result.res) {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "success",
          timerProgressBar: true,
          title: "Se actualizó correctamente",
        });
        this.reset();
      } else {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          timerProgressBar: true,
          title: result.error,
        });
      }
      this.loading = false;
    },
    getMeses(stringMes) {
      let valueMes = 0;
      switch (stringMes) {
        case "1 mes":
          valueMes = 1;
          break;
        case "3 meses":
          valueMes = 3;
          break;
      }
      return valueMes;
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>
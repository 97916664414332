<template>
  <div>
    <v-card>
      <v-tabs v-model="tab" background-color="primary" dark>
        <v-tab>Agregar suscripción</v-tab>
        <v-tab>Mis suscripciones</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <h1 class="mb-6">Formulario</h1>
              <FormularioSuscripcion></FormularioSuscripcion>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <h1 class="mb-6">Mis suscripciones</h1>
              <ListaSuscripciones :listSuscripciones="listSuscripciones"></ListaSuscripciones>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>
<script>
import FormularioSuscripcion from "@/components/suscripciones/FormularioSuscripcion";
import ListaSuscripciones from "@/components/suscripciones/ListaSuscripciones";
import { mapActions, mapState } from 'vuex';
export default {
  data() {
    return {
      tab: null,
    };
  },
  computed:{
    ...mapState('adminSuscripciones', ['listSuscripciones'])
  },
  components: {
    FormularioSuscripcion,
    ListaSuscripciones
  },
  methods:{
    ...mapActions('adminSuscripciones', ['obtieneSuscripciones'])
  },
  created(){
    this.obtieneSuscripciones();
  }
};
</script>
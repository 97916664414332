<template>
  <v-container>
    <div class="container-global">
      <h2 class="mb-6">Suscripciones</h2>
      <TabSuscripciones></TabSuscripciones>
    </div>
  </v-container>
</template>
<script>
import TabSuscripciones from "@/components/membresia/TabSuscripciones";
export default {
  data() {
    return {
      type: false,
    };
  },
  components: {
    TabSuscripciones,
  },
};
</script>
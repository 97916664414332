<template>
  <div class="card-user">
    <div class="data-user">
      <div
        class="icon"
        v-bind:style="{ 'background-image': 'url(' + require('../assets/user-default.png') + ')' }"
      ></div>
      <p class="titleUser">{{item.email}}</p>
    </div>
    <div class="data-restaurantes">
      <div v-if="item.restaurantes.length > 0">
        <p v-for="(restaurante, index) in item.restaurantes" :key="index">
          <span class="title-restaurante primary--text">Restaurante: </span>
          <router-link class="link-menu" target='_blank' :to="{name: 'Menu', params: { id: restaurante.slug }}">{{restaurante.nombre}}</router-link>
        </p>
      </div>
    </div>
    <div class="container-activo">
      <v-switch
        color="secondary"
        label="Activo"
        v-model="item.estatus"
        v-on:change="openDialogEstatus(item.estatus)"
      ></v-switch>
      <!-- modal estatus usuarios -->
      <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Estas seguro que deseas {{nameEstatus}} {{item.email}}?</v-card-title>
            <v-card-actions>
              <v-btn color="errorColor" text @click="cancelarActualizar()">Cancelar</v-btn>
              <v-btn
                color="secondary"
                text
                @click="confirmActualizar(item)"
                :loading="loading"
              >Confirmar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  props: {
    item: Object
  },
  data() {
    return {
      dialog: false,
      nameEstatus: "",
      respuesta: "",
      loading: false
    };
  },
  watch: {
    dialog(val) {
      val || this.closeModalEstatus();
    }
  },
  methods: {
    ...mapActions("adminUsers", ["actualizarEstatus"]),
    async confirmActualizar(item) {
      let usuario = {
        email: item.email,
        estatus: item.estatus,
        foto: item.foto,
        uid: item.uid
      };
      this.loading = true;
      this.respuesta = item.estatus ? "activo" : "desactivó";
      const result = await this.actualizarEstatus(usuario);
      if (result.res) {
        this.dialog = false;
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "success",
          timerProgressBar: true,
          title: "Se " + this.respuesta + " correctamente."
        });
      } else {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          timerProgressBar: true,
          title: result.error
        });
      }
      this.loading = false;
    },
    openDialogEstatus(estatus) {
      this.dialog = true;
      this.nameEstatus = estatus ? "activar" : "desactivar";
    },
    closeModalEstatus() {
      this.dialog = false;
    },
    cancelarActualizar() {
      this.dialog = false;
      this.item.estatus = !this.item.estatus;
    }
  }
};
</script>

<style lang="scss">
.link-menu{
  color:#070707 !important;
  display: inline-block;
}
</style>
<template>
  <ul class="list-users">
    <li v-for="(item, index) in listUsuariosData" :key="index">
      <CardUsuario :item="item"></CardUsuario>
    </li>
  </ul>
</template>
<script>
import { mapActions, mapState } from "vuex";
import CardUsuario from "@/components/CardUsuario";
export default {
  data() {
    return {
      listUsuariosData: []
    };
  },
  components:{
    CardUsuario
  },
  computed: {
    ...mapState("adminUsers", ["listUsuarios"])
  },
  methods: {
    ...mapActions("adminUsers", ["obtieneUsuarios"]),
    async initData() {
      await this.obtieneUsuarios();
      this.listUsuariosData = this.listUsuarios;
    }
  },
  created() {
    this.initData();
  }
};
</script>
<style lang="scss">
.list-users {
  box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
  width: 100%;
  background-color: #ffffff;
  list-style: none;
  margin: 0;
  padding: 0 !important;
  li {
    padding: 12px 0;
    &:not(:last-child) {
      border-bottom: solid 0.5px #ebebeb;
    }
    .card-user {
      display: flex;
      align-items: center;
      @media (max-width: 1024px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }
    p {
      margin-bottom: 0;
    }
    .title-restaurante {
      font-weight: 500;
    }
    .data-user {
      flex-basis: 40%;
      padding: 0 24px;
      display: flex;
      align-items: center;
      .icon {
        height: 50px;
        width: 50px;
        flex-shrink: 0;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        margin-right: 25px;
        @media (max-width: 1024px) {
          margin-right: 0;
          margin-bottom: 16px;
        }
      }
      @media (max-width: 1024px) {
        flex-basis: 100%;
        flex-direction: column;
        align-items: flex-start;
      }
    }
    .data-restaurantes {
      flex-basis: 40%;
      @media (max-width: 1024px) {
        flex-basis: 100%;
        padding: 0 24px;
      }
    }
    .container-activo {
      flex-basis: 20%;
      padding: 0 24px;
      @media (max-width: 1024px) {
        flex-basis: 100%;
      }
    }
  }
}
</style>
<template>
  <v-container>
      <div class="container-global ma-auto">
        <h2 class="mb-6">Lista de usuarios</h2>
        <ListaUsuarios></ListaUsuarios>
      </div>
  </v-container>
</template>
<script>
import ListaUsuarios from "@/components/ListaUsuarios";
export default {
  components: {
    ListaUsuarios
  },
}
</script>
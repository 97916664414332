<template>
  <v-container class="container-menu">
    <div
      class="img-fondo"
      v-show="menu.tipoFondo == 'imagen'"
      v-bind:style="{ 'background-image': 'url(' + menu.imgFondo + ')' }"
    ></div>
    <div
      class="fondo-color"
      v-show="menu.tipoFondo == 'color'"
      v-bind:style="{ 'background-color': menu.colorFondo }"
    ></div>
    <div class="img-menu-container">
      <v-img
      contain
      width="100%"
      max-height="200px"
      :src="menu.imgPrincipal"
      v-show="menu.imgPrincipal"
      class="mb-8"
    ></v-img>
    </div>
    <div class="container-global pt-0 ma-auto">
      <h2
        class="text-center mb-8 title-menu"
        v-bind:style="{ color: menu.colorTextoPrincipal }"
      >
        Menú
      </h2>
      <ListMenu v-show="menu.listMenu" :categorias="menu.listMenu"></ListMenu>
    </div>
    <LoaderMenu v-show="showLoader"></LoaderMenu>
    <modalPlatilloImagen></modalPlatilloImagen>
  </v-container>
</template>
<script>
import modalPlatilloImagen from "@/components/menu/modalPlatilloImagen";
import LoaderMenu from "@/components/menu/LoaderMenu";
import { mapActions, mapState } from "vuex";
import ListMenu from "@/components/menu/ListMenu";
export default {
  data() {
    return {
      id: this.$route.params.id,
      showLoader: true,
    };
  },
  computed: {
    ...mapState(["menu"]),
  },
  components: {
    ListMenu,
    LoaderMenu,
    modalPlatilloImagen,
  },
  methods: {
    ...mapActions(["obtieneMenu"]),
    async obtieneMenuData() {
      await this.obtieneMenu(this.id);
      this.showLoader = false;
    },
  },
  created() {
    this.obtieneMenuData();
  },
};
</script>
<style lang="scss">
.img-menu-container{
  position: relative;
  z-index: 2;
  margin-left: -12px;
  margin-right: -12px;
  transform: translateY(-12px);
}
.title-menu {
  font-size: 32px;
  font-weight: normal;
}
.img-fondo {
  position: fixed;
  inset: 0px;
  z-index: -1;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 100vh;
  width: 100%;
  z-index: 1;
  opacity: 0.6;
  /* Large and up */
  @media screen and (min-width: 64em) {
    width: 400px;
    left: calc(44% - -20px);
  }
}
.fondo-color {
  position: fixed;
  inset: 0px;
  z-index: -1;
  height: 100vh;
  width: 100%;
  z-index: 1;
}
.container-menu {
  /* Large and up */
  @media screen and (min-width: 64em) {
    width: 380px !important;
    padding: 0 !important;
    max-width: unset;
  }
}
.container-menu .container-global {
  position: relative;
  z-index: 2;
  /* Large and up */
  @media screen and (min-width: 64em) {
    max-width: 100%;
  }
}
</style>
<template>
  <ul class="list-suscripciones">
    <li v-for="(item, index) in listSuscripciones" :key="index">
      <div class="card-suscripcion" :class="isEndTime(item.vencido)">
        <h2 class="mb-2">Datos del usuario</h2>
        <div class="datos-plan">
          <p class="mb-0">Correo: {{ item.correoEmail }}</p>
          <p class="mb-0">Nombre del plan: {{ item.nombreMembresia }}</p>
          <p class="mb-0">Precio : ${{ item.precio }}</p>
          <p class="mb-0">
            Fecha Inicio: {{ item.fechaInicio | formatDateShort }}
          </p>
          <p class="mb-4">Fecha Fin: {{ item.fechaFin | formatDateShort }}</p>
          <h4>Servicios de la membresia:</h4>
          <p class="mb-0">Encuesta: {{ item.encuesta | boolToString }}</p>
          <p class="mb-0">Subir Excel: {{ item.subir | boolToString }}</p>
          <p class="mb-4">Redes Sociales: {{ item.redes | boolToString }}</p>
          <h4 class="mb-0">Estado de la suscripcion: {{ item.estatus | boolSuscripcion }}</h4>
          <v-btn
            color="accent"
            class="white--text mt-8 pr-8 pl-8 btn-margin"
            :loading="loading"
            :disabled="!item.estatus"
            @click="cancelarSuscripcion(item)"
            >Finalizar suscripción</v-btn
          >
        </div>
      </div>
    </li>
  </ul>
</template>
<script>
import moment from "moment";
import { mapActions } from "vuex";
export default {
  props: {
    listSuscripciones: Array,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ...mapActions("adminSuscripciones", ["desactivarSuscripcion"]),
    isEndTime(vencido) {
      return vencido ? "vencido" : "";
    },
    moment: function () {
      return moment();
    },
    async cancelarSuscripcion(item) {
      this.loading = true;
      const result = await this.desactivarSuscripcion(item);
      if (result.res) {
        item.estatus = false;
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "success",
          timerProgressBar: true,
          title: "Se finalizó la suscripción correctamente",
        });
      } else {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          timerProgressBar: true,
          title: result.error,
        });
      }
      this.loading = false;
    },
  },
  filters: {
    formatDateShort: function (date) {
      return moment(date, "MMMM Do YYYY, h:mm:ss a").format("YYYY/MM/DD");
    },
    boolToString: function (bool) {
      return bool ? "Si" : "No";
    },
    boolSuscripcion: function (bool) {
      return bool ? "Activa" : "Finalizada";
    },
  },
};
</script>
<style lang="scss">
.list-suscripciones {
  list-style: none;
  margin: 0;
  padding: 0 !important;
}
.card-suscripcion {
  display: flex;
  flex-direction: column;
  box-shadow: 5px 5px 10px;
  padding: 25px;
  border-radius: 8px;
  &.vencido {
    background-color: #ff6363;
    color: #ffffff;
  }
}
</style>
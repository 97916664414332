<template>
  <div class="containerLoader">
    <div class="icon-menu">
      <lottie-animation path="animationMenu.json" :loop="true" :autoPlay="true" />
    </div>
    <div class="loader-circle"></div>
  </div>
</template>
<script>
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
export default {
  methods: {
    getHeight() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }
  },
  components: {
    LottieAnimation
  },
  created() {
    this.getHeight();
  }
};
</script>
<style lang="scss">
.containerLoader {
  position: fixed;
  top: 0;
  left: 0;
  height: calc(var(--vh, 1vh) * 100);
  background-color: #ffffff;
  width: 100%;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .icon-menu {
    height: 250px;
    max-width: 80%;
  }
  .loader-circle {
    display: block;
    width: 60px;
    height: 60px;
    margin: 0 auto;
    border: 3px solid transparent;
    border-top-color: #3F51B5;
    border-bottom-color: #3F51B5;
    border-radius: 50%;
    animation: spin ease 1000ms infinite;
    @keyframes spin {
      to {
        transform: rotateZ(360deg);
      }
    }
  }
}
</style>
<template>
  <!-- subcategorias -->
  <div>
      <div class="card-subcategoria" v-for="(subcat, index) in subcategorias" :key="index">
          <h3 class="title-subcategoria">{{subcat.title}}</h3>
          <ListPlatillos :platillos="subcat.platillos"></ListPlatillos>
      </div>
  </div>
</template>
<script>
import ListPlatillos from "@/components/ListPlatillos";
export default {
  props:{
    subcategorias: Array
  },
  components: {
    ListPlatillos
  },
  data() {
    return {};
  },
  methods: {}
};
</script>
<style lang="scss">
  .title-subcategoria{
    font-size: 22px;
    margin-bottom: 6px;
  }
  .card-subcategoria:not(:last-child){
    border-bottom: 2px solid currentColor;
    margin-bottom: 16px;
  }
</style>